$font-path: '../assets/fonts';

@font-face {
  font-family: 'proxima_novathin';
  src: url('#{$font-path}/proximanova-thin-webfont.eot');
  src: url('#{$font-path}/proximanova-thin-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/proximanova-thin-webfont.woff2') format('woff2'),
       url('#{$font-path}/proximanova-thin-webfont.woff') format('woff'),
       url('#{$font-path}/proximanova-thin-webfont.ttf') format('truetype'),
       url('#{$font-path}/proximanova-thin-webfont.svg#proxima_novathin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novalight';
  src: url('#{$font-path}/proximanova-light-webfont.eot');
  src: url('#{$font-path}/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/proximanova-light-webfont.woff2') format('woff2'),
       url('#{$font-path}/proximanova-light-webfont.woff') format('woff'),
       url('#{$font-path}/proximanova-light-webfont.ttf') format('truetype'),
       url('#{$font-path}/proximanova-light-webfont.svg#proxima_novalight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novaregular';
  src: url('#{$font-path}/proximanova-regular-webfont.eot');
  src: url('#{$font-path}/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}/proximanova-regular-webfont.woff2') format('woff2'),
        url('#{$font-path}/proximanova-regular-webfont.woff') format('woff'),
        url('#{$font-path}/proximanova-regular-webfont.ttf') format('truetype'),
        url('#{$font-path}/proximanova-regular-webfont.svg#proxima_novaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novasemibold';
  src: url('#{$font-path}/proximanova-semibold-webfont.eot');
  src: url('#{$font-path}/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/proximanova-semibold-webfont.woff2') format('woff2'),
       url('#{$font-path}/proximanova-semibold-webfont.woff') format('woff'),
       url('#{$font-path}/proximanova-semibold-webfont.ttf') format('truetype'),
       url('#{$font-path}/proximanova-semibold-webfont.svg#proxima_novablack') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novabold';
  src: url('#{$font-path}/proximanova-bold-webfont.eot');
  src: url('#{$font-path}/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/proximanova-bold-webfont.woff2') format('woff2'),
       url('#{$font-path}/proximanova-bold-webfont.woff') format('woff'),
       url('#{$font-path}/proximanova-bold-webfont.ttf') format('truetype'),
       url('#{$font-path}/proximanova-bold-webfont.svg#proxima_novabold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novablack';
  src: url('#{$font-path}/proximanova-black-webfont.eot');
  src: url('#{$font-path}/proximanova-black-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/proximanova-black-webfont.woff2') format('woff2'),
       url('#{$font-path}/proximanova-black-webfont.woff') format('woff'),
       url('#{$font-path}/proximanova-black-webfont.ttf') format('truetype'),
       url('#{$font-path}/proximanova-black-webfont.svg#proxima_novablack') format('svg');
  font-weight: 900;
  font-style: normal;
}
