@import '../../styles/base/partials/colors';

.all-checkbox {
  border-bottom: 2px solid #ececec;
  .lg-location-checkbox {
    font-weight: 600;
  }
  .partial-check {
    content: '';
    background: $white url('../../images/checkbox-dash.svg') no-repeat center;
    border: 2px solid $action-100;
  }
}
