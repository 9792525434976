.button {
  border: 1px solid transparent;
  border-radius: rem-calc(100px);
  cursor: pointer;
  display: inline-block;
  font-family: 'proxima_novasemibold', helvetica, serif;
  font-size: rem-calc(16px);
  line-height: 100%;
  margin-bottom: 16px;
  outline: none;
  padding: rem-calc(12px 32px);
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    background: transparent;
  }

  &.button-large {
    font-size: rem-calc(20px);
    padding: rem-calc(16px 48px);
  }

  &.button-md {
    font-size: rem-calc(16px);
    padding: rem-calc(12px);
  }

  &.button-small {
    font-size: rem-calc(14px);
    padding: rem-calc(8px 24px);
  }

  &.expanded {
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.button-secondary {
  background-color: transparent;
  border: 1px solid;
  border-color: $black;
  color: $black;

  &:hover {
    border-color: $blackOverlay60;
    box-shadow: map-get($box-shadows, 'shadow-2');
    color: $blackOverlay50;
  }

  &:focus {
    color: $blackOverlay50;
  }

  &:active {
    background-color: $blackOverlay05;
    border-color: $blackOverlay60;
    box-shadow: none;
    color: $blackOverlay50;
    position: relative;
  }

  &:disabled {
    background-color: $gray10;
    border-color: transparent;
    color: $gray50;

    &:hover {
      box-shadow: none;
    }
  }
}

.button-primary {
  background-color: $black;
  border: 1px solid $black;
  color: $white;

  &:visited {
    background-color: $black;
    border: 1px solid $black;
    color: $white;

    &:hover {
      background-color: $gray130;
      border-color: $gray130;
      box-shadow: map-get($box-shadows, 'shadow-2');
    }
  }

  &:focus {
    background: $black;
  }

  &:active {
    background-color: $gray130;
    border-color: $gray130;
    color: $white;

    &:hover {
      background-color: $gray130;
      border-color: $gray130;
      box-shadow: map-get($box-shadows, 'shadow-2');
      color: $white;
    }
  }

  &:hover {
    background-color: $gray130;
    border-color: $gray130;
    box-shadow: map-get($box-shadows, 'shadow-2');
    color: $white;
  }
}
