@import '../../styles/base/partials/colors';

.lg-hidden-checkbox {
  position: fixed;
  opacity: 0;
  bottom: -999px;
}
.checkbox-div {
  display: inline-block;
}

.country-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid $gray10;

  &.country-active {
    background-color: $gray10;
    border: 2px solid $gray10;
  }

  &:hover {
    background-color: $gray5;
  }

  input {
    align-items: center;
    margin-bottom: 0;
  }

  label {
    font-size: 1rem;
  }
}
