@import '../../styles/base/partials/_colors.scss';

.searchbar {
  background-color: $white;
  border-bottom: 0.5px solid $gray10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  position: sticky;
  input {
    margin: 1rem;
    line-height: 1.5;
    padding: 0.2rem 0.9rem 0.2rem 0.9rem;
    width: 100%;
    height: 2rem;
    font-family: inherit;
    border: 1px solid #dad8d6;
    border-radius: 4px;
    box-shadow: none;
    letter-spacing: -0.2px;
    &::placeholder {
      color: #c6c6c6;
    }
  }
}
