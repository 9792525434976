.lg-filter-header-container {
  position: relative;

  padding: 8px 0;
}

.lg-header-container {
  position: relative;

  .lg-header {
    display: inline-block;
    margin-bottom: 0;
  }

  .lg-filter-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 44px;
    height: 44px;
    text-align: center;
    vertical-align: middle;

    &.active {
      .lg-filter-btn-border {
        width: 34px;
        height: 34px;
        background: rgba(170, 170, 170, 0.2);
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .lg-filter-img {
      width: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.lc-modal-content {
  margin-left: 1rem;
  margin-right: 1rem;
  .lg-checkbox-group-container.locations-containers {
    .lg-checkbox-group.locations.dropdown.lg-grey {
      border-radius: 4px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 84px !important; // Height of lc-modal-footer.
  }
}

.lc-modal-footer {
  bottom: 0 !important;
  padding: 0 2rem 94px 2rem;
}

.lg-results-label {
  font-size: 14px;
  color: #00946d;
}

.lg-results-text {
  color: #00946d;
  font-size: 14px;
  margin-top: 2em;
}

.lg-modal-btn-group {
  margin-top: 1em;

  @media only screen and (max-width: 767px) {
    .button-md {
      width: 47%;
    }
  }

  button + button {
    margin-left: 16px;
  }
}
