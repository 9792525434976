.lg-hidden-checkbox {
  position: fixed;
  opacity: 0;
  bottom: -999px;
}
.checkbox-div {
  display: inline-block;
}
.state-text {
  background-color: #f6f6f6;
  border-bottom: 1px solid #ececec;
  color: #666667;
  font-style: normal;
  font-weight: 400;
  line-height: 19.09px;
  margin: 0;
  padding: 0.375rem 1rem 0.375rem 1rem;
  width: 100%;
}

/*Hide the checkmark by default*/
.checkbox.location-checkbox input[type='checkbox'] + label::after {
  content: none;
}
/*Unhide the checkmark on the checked state*/
.checkbox.location-checkbox input[type='checkbox']:checked + label::after {
  content: '';
  background: url('../../images/checkmark-white.svg');
}

.checkbox.location-checkbox input[type='checkbox']:checked + label::before {
  background-color: #009ecc;
  border-color: #009ecc;
}
