@import '../../styles/base/partials/_colors.scss';

.list-container {
  background-color: #f2f2f2;

  @media only screen and (max-width: 640px) {
    padding-top: 20px;
  }
}
.list-container .loading {
  text-align: center;
}

.list-container .loading span {
  color: $lifegroups-green;
  padding-left: 8px;
}

.list-container .start-a-group {
  text-align: center;
}

.list-container h1.title {
  font-family: proxima_novabold, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
}

.list-container p.subtitle{
  line-height: 21px;
  font-size: 16px;
  letter-spacing: -0.2px;
}

.list-container .subtitle a {
  font-family: proxima_novabold, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 769px) {
  .list-container {
    padding-top: 2rem;
  }
  
  .list-container h1.title {
    font-size: 28px;
  }
}
