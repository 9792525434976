.announcement {
  padding: 1rem 0;
  position: relative;
}
.announcement .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.announcement p {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

@media screen and (min-width: 1024px) {
  .announcement p {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
}

.announcement .small-button {
  background-color: white;
  color: blue;
}
.announcement strong {
  font-family: "proxima_novabold", helvetica, serif;
}