// Margin and Padding

.m-x-auto {
  margin-right: auto !important;
  margin-left:  auto !important;
}

.m-l-auto {
  margin-left:  auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

@mixin spacings($length-x, $length-y, $abbrev, $size, $prop) {
  .#{$abbrev}-a-#{$size} { #{$prop}:        rem-calc($length-y $length-x) !important; } // a = All sides
  .#{$abbrev}-t-#{$size} { #{$prop}-top:    rem-calc($length-y) !important; }
  .#{$abbrev}-r-#{$size} { #{$prop}-right:  rem-calc($length-x) !important; }
  .#{$abbrev}-b-#{$size} { #{$prop}-bottom: rem-calc($length-y) !important; }
  .#{$abbrev}-l-#{$size} { #{$prop}-left:   rem-calc($length-x) !important; }

  // Axes
  .#{$abbrev}-x-#{$size} {
    #{$prop}-right:  rem-calc($length-x) !important;
    #{$prop}-left:   rem-calc($length-x) !important;
  }
  .#{$abbrev}-y-#{$size} {
    #{$prop}-top:    rem-calc($length-y) !important;
    #{$prop}-bottom: rem-calc($length-y) !important;
  }
}
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);
    @include spacings($length-x, $length-y, $abbrev, $size, $prop);
  }
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $breakpoint-abbr, $breakpoint-foundation in $breakpoint-abbrs {
    @each $size, $lengths in $spacers {
      $length-x:   map-get($lengths, x);
      $length-y:   map-get($lengths, y);
      @include breakpoint($breakpoint-foundation) {
        $bpabbrev: "breakPoint#{$breakpoint-abbr}-#{$abbrev}";
        @include spacings($length-x, $length-y, $bpabbrev, $size, $prop);
      }
    }
  }
}

