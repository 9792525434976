$box-shadows: (
  "shadow-2": 0px 2px 6px 0px rgba(0, 0, 0, 0.25),
) !default;

@each $name, $value in $box-shadows {
  .box-#{$name} {
    box-shadow: $value;
    transition: box-shadow 0.25s;
  }
  .box-#{$name}-hover:hover {
    box-shadow: $value;
    transition: box-shadow 0.25s;
  }
}