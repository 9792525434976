label.lg-day-checkbox {
  padding: 2px 16px 0 16px;
  height: 33px;
  margin: 0 10px 10px 0;
  text-align: center;
}
.checkbox-div label.lg-day-checkbox {
  padding: 2px 16px 0 16px;
  height: 33px;
  margin: 0 10px 10px 0;
  text-align: center;

  @media only screen and (max-width: 1199px) {
    padding-top: 0;
  }
}
.lg-day-checkbox.lg-active {
  color: #FFFFFF;
  background-color: #0EA9D6;
  border-color: #0EA9D6;
}
