
.hidden-sm {
  @media only screen and (max-width: 640px) {
    display: none;
  }
}

.hidden-md {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.hidden-lg {
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.hidden-xl {
  display: none;
}

