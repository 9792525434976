@import '../../styles/base/partials/_colors.scss';

.lifegroups-search {
  background-color: $white;
  text-align: left;
  overflow: visible;
}

.lifegroups-search input::placeholder {
  color: #bdbdbd;
}

.lifegroups-search .lg-filter-header-container {
  padding-top: 16px;
}

.lifegroups-search .lg-day-dropdown,
.lifegroups-search .lg-category-dropdown {
  display: none;
}

.lifegroups-search .lg-search-options {
  max-height: 800px;
  transition: all 1s;
}

.lifegroups-search .lg-search-container {
  max-width: 500px;
}

.lifegroups-search .SearchContainer {
  text-align: left;
  padding-left: 3rem;
}

.lifegroups-search .lg-blue {
  color: $action-120;
  border-color: $action-120;
  background-color: $action-10;
}

.lifegroups-search input.lg-form-keyword {
  display: inline-block;
  padding-left: 16px;
  width: calc(100% - 81px);
  height: 48px;
  border-radius: 4px;
  font-family: 'proxima_novaregular', helvetica, serif;
}

.lifegroups-search input.lg-form-age {
  height: 48px;
  width: 64px;
  border-radius: 4px;
  display: inline-block;
  padding: 1rem;
  text-align: center;
}

.lifegroups-search input.lg-category-form-age {
  display: none;
}

.lifegroups-search .dropdown-container {
  margin-bottom: 24px;
}

.lifegroups-search .lg-header {
  text-align: center;
  font-size: 23px;
  line-height: 28px;

  @media only screen and (max-width: 640px) {
    max-width: 250px;
  }

  @media only screen and (min-width: 769px) {
    min-width: 100%;
  }
}

.lifegroups-search.lg-minimize .lg-search-options {
  overflow: hidden;
  height: 0;
  transition: all 1s;
  max-height: 0;
}

.lifegroups-search .lg-found-text,
.lifegroups-search .lg-checkbox-group,
.lifegroups-search .lg-checkbox-group-text {
  display: none;
}

.lifegroups-search .no-result {
  padding: 1rem 0 0 1rem;
  font-weight: 400;
}

.lifegroups-search .no-result-description {
  padding: 1rem 1rem 0.5rem 1rem;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  margin: 0;
}

.lifegroups-search .lg-checkbox-group-container {
  margin-left: 8px;
}

.lifegroups-search .lg-checkbox-group-container:focus {
  outline: none;
}

.lifegroups-search .categories-text {
  font-weight: bold;
  display: block;
  color: $black;
  line-height: 100%;
}

.lifegroups-search .lg-day-checkbox-container {
  margin-bottom: 12px;
}

.lifegroups-search .lg-group-type-checkbox-container {
  display: flex;
  justify-content: space-between;
  border: 2px solid #dad8d6;
  border-radius: 100px;
  color: #999899;
  margin: 12px 0;
  background-color: #f6f6f6;
}

.mobile-days-text {
  line-height: 100%;
}

.lifegroups-search .categories-custom-checkbox-container {
  padding-top: 16px;
  padding-bottom: 16px;
}

.lifegroups-search .categories-age-text {
  display: none;
}

.lifegroups-search .lg-custom-checkbox {
  color: $gray100;
}

@media only screen and (min-width: 769px) {
  .lifegroups-search .lg-search-options {
    background-color: $white;
    transition: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .lg-scroll-to-top {
    position: relative;
    z-index: 1;
  }

  .lifegroups-search {
    background-color: initial;
  }

  .lifegroups-search .lg-search-container {
    max-width: 100%;
  }

  .lifegroups-search .lg-group-type-checkbox-container {
    display: flex;
    justify-content: space-between;
    border: 2px solid #dad8d6;
    border-radius: 100px;
    color: #999899;
    margin: 12px 0;
    background-color: #f6f6f6;
  }

  .lifegroups-search .lg-search-filters {
    display: flex;
    align-items: flex-start;
    padding-top: 8px;
    width: 100%;
  }

  .lifegroups-search .lg-header {
    text-align: left;
    display: inline-block;
    margin-bottom: 0;
    padding-left: 16px;
    transform: translateY(4px);
  }

  .lifegroups-search.lg-filter-header-container {
    display: block;
    padding-top: 16px;
  }

  .lifegroups-search .lg-found-text {
    display: inline-block;
    font-size: 14px;
    padding-left: 16px;
    color: $lifegroups-green;
    margin-bottom: 0;
  }

  .lifegroups-search .lg-checkbox-group {
    display: flex;
    position: relative;
    height: 100%;
    margin-bottom: 8px;
    padding: 3px 12px 3px 12px;
    border: 1px solid #dad8d6;
    border-radius: 100px;
    color: #999899;
    line-height: 24px;
    cursor: pointer;
  }

  .lifegroups-search .lg-checkbox-group.day {
    width: 100%;
  }

  .lifegroups-search .lg-checkbox-group.category {
    width: 100%;
  }

  .lifegroups-search .menu-text {
    padding-right: 1rem;
  }

  .lifegroups-search .lg-blue {
    color: $action-120;
    border-color: $action-120;
    background-color: $action-10;
  }

  .lifegroups-search .lg-grey {
    color: #999899;
    border-color: #dad8d6;
  }

  .lifegroups-search label.lg-day-checkbox {
    margin: 0 10px 10px 0;
  }

  .lifegroups-search .lg-checkbox-container,
  .lifegroups-search .mobile-days-text {
    display: none;
  }

  .lifegroups-search .lg-checkbox-container.lg-show-dropdown {
    display: block;
    background-color: $white;
    position: absolute;
    margin-left: 8px;
    z-index: 1;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .lifegroups-search .lg-checkbox-container.day.lg-show-dropdown {
    width: 376px;
  }

  .lifegroups-search .lg-checkbox-container.category.lg-show-dropdown {
    width: 500px;
  }

  .lifegroups-search .lg-checkbox-group-text {
    display: block;
    margin-bottom: 0;
  }

  .lifegroups-search .desktop-days-text {
    padding: 16px 0 0 16px;
  }

  .lifegroups-search .desktop-group-type-text {
    padding: 16px 0 0 16px;
  }

  .lifegroups-search .categories-text {
    padding-top: 16px;
    padding-bottom: 8px;
    font-weight: normal;
    color: $gray100;
  }

  .lifegroups-search .categories-checkbox-text {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 16px;
  }

  .lifegroups-search .lg-day-checkbox-container {
    padding-left: 16px;
    padding-top: 8px;
  }

  .lifegroups-search .lg-down-arrow-zone {
    position: relative;
  }

  .lifegroups-search .lg-up-arrow-zone {
    position: relative;
  }

  .lifegroups-search .lg-down-arrow {
    height: 12px;
    width: 12px;
    background: url('../../images/arrow-down.svg') no-repeat;
    background-size: 12px;
    border: none;
    background-position: right top;
    display: inline-block;
  }

  .lifegroups-search .lg-up-arrow {
    height: 12px;
    width: 12px;
    background: url('../../images/arrow-up.svg') no-repeat;
    background-size: 12px;
    border: none;
    background-position: right top 4px;
    display: inline-block;
  }

  .lifegroups-search .lg-category-checkbox-container {
    padding-left: 16px;
    padding-bottom: 6px;
    padding-top: 6px;
  }

  .lifegroups-search .lg-form-age-container {
    padding-left: 16px;
    box-shadow: inset 0 1px 0 $gray10;
  }

  .lifegroups-search .lg-input-container {
    display: inline-block;
    margin-left: 8px;
  }

  .lifegroups-search .lg-inputs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .lifegroups-search .lg-inputs input {
    box-shadow: none;
    border-radius: 100px;
    border: 1px solid #dad8d6;
    height: 32px;
    color: $gray100;
    display: inline-block;
    padding: 5px 0;
    line-height: 24px;
  }

  .lifegroups-search input.lg-form-age {
    display: none;
  }

  .lifegroups-search input.lg-category-form-age {
    display: inline-block;
    border: 1px solid #dad8d6;
    border-radius: 4px;
    width: 64px;
    height: 32px;
    font-family: 'proxima_novaregular', helvetica, serif;
  }

  .lifegroups-search input.lg-form-keyword {
    padding-left: 16px;
    width: 100%;
  }

  .lifegroups-search input::placeholder {
    color: #bdbdbd;
  }

  .lifegroups-search .dropdown-container {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 8px;
    max-width: 144px;
    width: 100%;

    &:first-child {
      margin-left: 0;
    }
  }

  .lifegroups-search .categories-custom-checkbox-container {
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: inset 0px 1px 0px $gray10;
  }

  .lifegroups-search .categories-age-text {
    display: block;
  }
}

@media only screen and (min-width: 769px) {
  .lifegroups-search .lg-header {
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 0;
    min-width: 165px;
  }
}

@media only screen and (min-width: 1023px) {
  .lifegroups-search .lg-filter-header-container {
    padding-top: 16px;
  }

  .lifegroups-search .lg-found-text {
    padding-top: 12px;
    padding-left: 8px;
    order: 3;
    min-width: 155px;
  }
}

@media only screen and (min-width: 1200px) {
  .lifegroups-search .lg-filter-header-container {
    width: 1152px;
    margin: auto;
  }
}

@media only screen and (max-width: 769px) {
  .lifegroups-search {
    .lg-search-container {
      max-width: initial;
    }

    .dropdown-container {
      max-width: initial;
    }

    label {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 5px;
    }

    input.lg-form-age,
    input.lg-form-keyword {
      width: 100%;
    }

    input.lg-form-age {
      text-align: left;
    }
  }
}
