@import '../../styles/base/partials/_colors.scss';
@import '../../styles/mixins/breakpoints';

.countries-container {
  position: relative;

  @include md {
    margin-left: 8px;

    .mobile-dropdown-header {
      display: none;
    }
  }

  .countries-dropdown {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 8px;
    padding: 12px 16px;
    border: 1px solid $menu-border-gray;
    border-radius: 4px;
    color: $location-dropdown-text-gray;
    cursor: pointer;

    @include md {
      width: 100%;
      border-radius: 100px;
      padding: 3px 12px;
      line-height: 24px;
    }

    .lg-down-arrow-zone {
      position: relative;

      .lg-down-arrow {
        height: 12px;
        width: 12px;
        background: url('../../images/arrow-down.svg') no-repeat;
        background-size: 12px;
        border: none;
        background-position: right top;
      }
    }

    .lg-up-arrow-zone {
      position: relative;

      .lg-up-arrow {
        height: 12px;
        width: 12px;
        background: url('../../images/arrow-up.svg') no-repeat right top 4px;
        background-size: 12px;
        border: none;
        display: inline-block;
      }
    }
  }

  .dropdown-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    border: 1px solid $menu-border-gray;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    color: $gray100;
    background-color: $white;
    width: 100%;
    height: 428px;
    position: absolute;
    overflow-y: auto;
    z-index: 1;

    @include sm {
      left: 0;
    }

    @include md {
      width: 343px;
      margin-left: 8px;
    }
  }

  .dropdown-outer-open {
    display: block;
  }

  .dropdown-outer-closed {
    display: none;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.country-dropdown {
  border: 1px solid $menu-border-gray;
  border-radius: 4px;
  width: 343px;
  height: 428px;
  color: 100;
  flex-direction: column;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow-y: auto;

  .country-list {
    width: 100%;
    overflow-y: auto;
  }

  .group-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .group-text {
      margin: 0;
      line-height: 19.09px;
      float: left;
    }
    .group-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $gray10;
      font-weight: 600;
      padding: 1rem;
    }
  }
}

.individual-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
  padding: 1rem;
  border-bottom: 1px solid $gray10;
}
.group-container.area-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid $gray10;
}
.child-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem 1rem 2.25rem;
}

.children {
  font-weight: 400;
  width: 100%;
}
.campus {
  font-weight: 400;
  margin: 0;
  line-height: 19.09px;
  color: $gray100;
  font-style: normal;
}
