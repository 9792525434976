@import 'foundation_and_overrides';
@import 'src/styles/base/base.scss';

body,
html {
  height: 100%;
}

body {
  font-family: $nova-regular !important;
  -webkit-font-smoothing: antialiased;
}

h1,
h2 {
  font-family: $nova-bold;
  letter-spacing: -1px;
}

h3,
h4,
h5,
h6 {
  font-family: $nova-semibold;
}

a {
  color: $primary-blue;
  &:visited,
  &:active {
    color: $primary-blue;
  }

  &:hover {
    color: darken($primary-blue, 10);
  }
}

ul {
  margin-left: 2em;
}

ol {
  li {
    list-style-type: decimal;
  }
}

button,
input[type='submit'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  font-family: $nova-regular;
}

.no-scroll {
  overflow: hidden;
}
