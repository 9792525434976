@import '../../styles/base/partials/_colors.scss';

label.lg-category-checkbox {
  padding: 2px 16px 0 16px;
  height: 33px;
  margin: 0 10px 10px 0;

  @media only screen and (max-width: 1199px) {
    padding-top: 0;
  }
}
.checkbox-div label.lg-category-checkbox {
  padding: 2px 16px 0 16px;
  height: 33px;
  margin: 0 10px 10px 0;

  @media only screen and (max-width: 1199px) {
    padding-top: 0;
  }
}
.lg-category-checkbox.lg-active {
  color: $white;
}
.lg-category-singles.lg-active {
  background-color: $singles;
  border-color: $singles;
}
.lg-category-mens.lg-active {
  background-color: $mens;
  border-color: $mens;
}
.lg-category-shared-interest.lg-active {
  background-color: $shared-interest;
  border-color: $shared-interest;
}
.lg-category-womens.lg-active {
  background-color: $womens;
  border-color: $womens;
}
.lg-category-married.lg-active {
  background-color: $married;
  border-color: $married;
}
.lg-category-everyone-welcome.lg-active {
  background-color: $everyone-welcome;
  border-color: $everyone-welcome;
}
.lg-category-parents.lg-active {
  background-color: $lcparents;
  border-color: $lcparents;
}
.lg-category-young-adults.lg-active {
  background-color: $young-adults;
  border-color: $young-adults;
}
.lg-category-college.lg-active {
  background-color: $college;
  border-color: $college;
}
.lg-category-connect-locally.lg-active {
  background-color: $connect-locally;
  border-color: $connect-locally;
}
.lg-category-neighbor.lg-active {
  background-color: $neighbor;
  border-color: $neighbor;
}
.lg-category-mentor.lg-active {
  background-color: $mentor;
  border-color: $mentor;
}
.lg-category-watch-party.lg-active {
  background-color: $watch-party;
  border-color: $watch-party;
}

.lc-categories-container .lg-checkbox-group-text {
  padding-top: 8px;
  line-height: 14px;
}

.in-category-group-type-container {
  display: none;

  @media only screen and (min-width: 769px) {
    display: block;
    padding: 14px;
  }
}

.in-category-group-type-container:focus {
  outline: none;
}

.in-category-group-type-render {
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  border-radius: 100px;
  color: #999899;
}

.in-category-group-type-render .checkbox-div {
  text-align: center;
  width: 50%;
}

.in-category-group-type-render .lg-group-type-checkbox.lg-active {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
  width: 100%;
}
