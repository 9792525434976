// Documentation for typography lives here https://zeroheight.com/15e3694e7/p/60c967/b/19e4a9

@each $prop, $size in $font-sizes {
  .fontSize-#{$prop} {
    font-size: $size * $base-font-size;
  }
}

@each $breakpoint-abbr, $breakpoint-foundation in $breakpoint-abbrs {
  @each $prop, $size in $font-sizes {
    @include breakpoint($breakpoint-foundation) {
      .breakPoint#{$breakpoint-abbr}-fontSize-#{$prop} {
        font-size: $size * $base-font-size;
      }
    }
  }
}

strong {
  font-family: $nova-bold;
}

h1,
.title {
  font-family: $nova-black;
  font-weight: 900;
  font-size: rem-calc(40px);
  line-height: 100%;

  @include breakpoint('large') {
    font-size: rem-calc(64px);
  }
}

// These will be deprecated below
.fontSize-body {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: -0.1px;
}

.small {
  font-size: rem-calc(12px);
  letter-spacing: rem-calc(-0.1px);
}

// Text Helpers - Dan

// the font-weight-bold class is used but doesn't exist
.font-weight-bold,
.text-bold {
  font-weight: bold !important;
}

// Left
.text-left {
  text-align: left !important;
}

.text-left-lg {
  text-align: left !important;
}

.text-left-md {
  @media only screen and (max-width: 768px) {
    text-align: left !important;
  }
}

.text-left-sm {
  @media only screen and (max-width: 640px) {
    text-align: left !important;
  }
}

// Right

.text-right {
  text-align: right !important;
}

.text-right-lg {
  text-align: right !important;
}

.text-right-md {
  @media only screen and (max-width: 768px) {
    text-align: right !important;
  }
}

.text-right-sm {
  @media only screen and (max-width: 640px) {
    text-align: right !important;
  }
}

// Center

.text-center {
  text-align: center !important;
}

.text-center-lg {
  text-align: center !important;
}

.text-center-md {
  @media only screen and (max-width: 768px) {
    text-align: center !important;
  }
}

.text-center-sm {
  @media only screen and (max-width: 640px) {
    text-align: center !important;
  }
}
