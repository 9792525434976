.lg-checkbox-group-type-container {
  margin-bottom: 28px;
  @media only screen and (min-width: 769px) {
    display: none;
  }
}

.group-type-container {
  background-color: #f6f6f6;
  border-radius: 100px;
  color: #999899;
  display: flex;
  justify-content: space-between;

  /* Mobile only styles */
  @media only screen and (max-width: 768px) {
    display: block;
    border-radius: 25px;

    .checkbox-div {
      display: block;
      margin-bottom: 0;

      .lg-group-type-checkbox {
        display: block;
        border: none;

        padding: 5px 18px;

        &.lg-active {
          color: #fff;

          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
            0 3px 1px rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}

label.lg-group-type-checkbox {
  border-color: #f6f6f6;
  color: #666667;
  height: 33px;
  margin: 0 !important;
  padding: 2px 0;
  text-align: center;
  width: 100%;

  /* Mobile only styles */
  @media only screen and (max-width: 768px) {
    height: auto;
  }
}

.lg-checkbox-group-type-container:focus {
  outline: none;
}

.lg-group-type-checkbox.lg-active {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
