@import '../../styles/base/partials/colors';

.metro-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #ECECEC;

  .campus-active {
    background: $action-100 url('../../images/checkmark-white.svg') no-repeat center;
    border: 2px solid $action-100;
  }

  input {
    align-items: center;
    margin-bottom: 0;
  }

  label {
    font-size: 1rem;
  }

  .checkbox {
    height: 24px;
    width: 24px;
    left: 0;
    border: 2px solid #ececec;
    border-radius: 2px;
    margin: 0;

    &.campus-active {
      border: 2px solid $action-100;
    }

  }

  &:hover{
    .checkbox {
      background-color: #f4f4f4;
    }
    .checkbox.campus-active{
      background-color: $action-120;
      border: 2px solid $action-120;
    }
  }
  .lg-location-checkbox {
    font-weight: 600;
  }
  .partial-check {
    content: '';
    background: $white url('../../images/checkbox-dash.svg') no-repeat center;
    border: 2px solid $action-100;
  }
}
