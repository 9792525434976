.loading { 
  height: 300px; 
  object-fit: contain;
  background-color: #f2f2f2;
}

@media only screen and (min-width: 736px) { 
  .loading {
    height: 572px; 
    object-fit: contain;
  }
}
