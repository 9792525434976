@import '@lifechurch/web-tools-io/dist/themes/base/_colors.scss';

// LC Design System Colors

// Action Blue
$action-120: $actionBluePressed;
$action-100: $actionBlue;
$action-80: $actionBlueHover;
$action-10: map-get($labels, blueBgLight);

// Grayscale (many colors come in from Web Tools IO base theme, imported above).
$black: $black;
$LC-black: $black;
$gray100: $gray100 !default;
$gray80: $gray80;
$gray50: $gray50;

$blackOverlay05: rgba(0, 0, 0, 0.05);
$blackOverlay50: rgba(0, 0, 0, 0.5);
$blackOverlay60: rgba(0, 0, 0, 0.6);
$whiteOverlay05: rgba(255, 255, 255, 0.05);
$whiteOverlay50: rgba(255, 255, 255, 0.5);
$whiteOverlay60: rgba(255, 255, 255, 0.6);

$white: $white !default;
$blue: $actionBlue;
$blue-light: map-get($labels, blueBgLight);
$primary-blue: $actionBlue !default;

// Category Colors
$category-default: $black;
$college: $ministryCollegeLifeGroups;
$married: $ministryMarriedLifeGroups;
$singles: $ministrySinglesLifeGroups;
$mens: $ministryMensLifeGroups;
$shared-interest: $ministrySharedInterestsLifeGroups;
$womens: $ministryWomensLifeGroups;
$everyone-welcome: $ministryLifeGroupsOld; // Same hex as original hard-coded value.
$lcparents: $lcRed; // Same hex as original hard-coded value; no global parents value in theme.
$young-adults: $ministryYoungAdultLifeGroups;
$connect-locally: $ministryLocalMissions;
$neighbor: $ministryNeighborLifeGroups;
$mentor: $ministryMentorLifeGroups;
$watch-party: $ministryWatchPartyLifeGroups;

$lifegroups-green: $ministryLifeGroupsNew;
$local-partner: $ministryLifeMissions;

$menu-border-gray: #dad8d6; // No corresponding theme value for this.
$location-dropdown-text-gray: $gray50;

$colors: (
  'LC-black': $LC-black,
  'black': $black,
  'gray50': $gray50,
  'gray80': $gray80,
  'gray100': $gray100,
  'gray5': $gray5,
  'active': $primary-blue,
  'blue': $blue,
  'gray': $gray100,
  'action-100': $action-100,
);

@each $name, $value in $colors {
  .color-#{$name} {
    color: $value;
  }
  .backgroundColor-#{$name} {
    background-color: $value;
  }
  .borderColor-#{$name} {
    border-color: $value;
  }
}
