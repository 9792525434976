.lco-checkbox {
  .lg-location-checkbox {
    font-weight: 600;
  }
  .state-text {
    background-color: #f6f6f6;
    border-bottom: 1px solid #ececec;
    color: #666667;
    font-style: normal;
    font-weight: 400;
    line-height: 19.09px;
    margin: 0;
    padding: 0.375rem 0 0.375rem 1rem;
    width: 100%;
  }
}