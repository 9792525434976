@import '../../styles/base/partials/_colors.scss';
@import '../../styles/mixins/breakpoints';

.category-based-search {
  padding-bottom: 24px;
  padding-top: 32px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
  }

  p {
    margin: 0 0 4px;

    &:first-of-type {
      margin-top: 16px;
    }
  }

  span.group-type {
    color: $gray50;
  }

  .category-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px 0px 32px;
  }

  .dropdown-outer-container {
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    height: 400px;
    margin: 16px auto 24px auto;
    max-width: 784px;
    overflow: scroll;
    width: 100%;
  }

  @include md {
    padding-top: 64px;

    h2 {
      font-size: 32px;
    }

    .category-cards-container {
      margin: 32px 16px 40px;
    }

    .dropdown-outer-container {
      height: 368px;
    }
  }
}

.category-search-card {
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: auto;
  margin: 8px;
  min-height: 238px;
  max-width: 384px;
  overflow: hidden;
  transition: box-shadow 0.5s, transform 0.5s;
  width: 100%;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    transform: translateY(-4px);
  }

  .category-image-container {
    background-color: $gray5;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 152px;
    max-height: 152px;
    width: 100%;

    img {
      height: auto;
      margin: 0;
      width: 100%;
    }
  }

  .category-text-container {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    min-height: 86px;
    padding: 16px;

    h3.title {
      color: $black;
      font-size: 24px;
      font-weight: 700;
      line-height: 100%;
      margin: 0 0 4px;
    }

    p.description {
      color: $gray80;
      font-size: 20px;
      font-weight: 400;
      line-height: 100%;
      margin: 0;
    }
  }

  @include md {
    min-height: 288px;
    width: 384px;

    .category-image-container {
      height: 192px;
      max-height: 192px;
    }

    .category-text-container {
      min-height: 96px;
    }
  }
}

.category-button-container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;

  .group {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;

    &.align-center {
      justify-content: center;
    }

    &.align-left {
      justify-content: flex-start;
    }

    &.align-right {
      justify-content: flex-end;
    }

    @include md {
      width: 784px;
    }
  }

  @include md {
    width: 784px;

    .group {
      width: 784px;
    }
  }

  .button {
    &.icon-back,
    &.icon-next {
      display: block;
      position: relative;

      &::after {
        border-right: 1px solid $black;
        content: '';
        height: 7px;
        position: absolute;
        top: 19px;
      }

      &::before {
        border-right: 1px solid $black;
        content: '';
        height: 7px;
        position: absolute;
        top: 14px;
      }
    }

    &.icon-back {
      padding-left: 42px;

      &::after,
      &::before {
        left: 28px;
      }

      &::after {
        transform: rotate(-45deg);
      }

      &::before {
        transform: rotate(45deg);
      }
    }

    &.icon-next {
      padding-right: 42px;

      &::after,
      &::before {
        right: 28px;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    }

    &.button-secondary {
      &:hover,
      &:focus,
      &:active {
        color: $black;
      }
    }
  }
}
