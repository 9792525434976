@import 'partials/box_shadow';
@import 'partials/colors';


$nova-regular: 'proxima_novaregular', helvetica, serif;
$nova-semibold: 'proxima_novasemibold', helvetica, serif;
$nova-bold: 'proxima_novabold', helvetica, serif;
$nova-black: 'proxima_novablack', helvetica, serif;


// Sizes

$base-font-size: 16px;

$font-sizes: (
  xxs: 0.75,
  xs: 1,
  s: 1.5,
  m: 2,
  l: 2.5,
  xl: 3,
  xxl: 3.75
) !default;


// Breakpoints
$breakpoint-abbrs: (
  S: small,
  M: medium,
  L: large,
  XL: xlarge,
  XXL: xxlarge
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge);

// Spacing
$spacer: $base-font-size !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  half: (
    x: (
      $spacer-x * 0.5
    ),
    y: (
      $spacer-y * 0.5
    )
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  1-half:
    (
      x: (
        $spacer-x * 1.5
      ),
      y: (
        $spacer-y * 1.5
      )
    ),
  2: (
    x: (
      $spacer-x * 2
    ),
    y: (
      $spacer-y * 2
    )
  ),
  2-half:
    (
      x: (
        $spacer-x * 2.5
      ),
      y: (
        $spacer-y * 2.5
      )
    ),
  3: (
    x: (
      $spacer-x * 3
    ),
    y: (
      $spacer-y * 3
    )
  ),
  3-half:
    (
      x: (
        $spacer-x * 3.5
      ),
      y: (
        $spacer-y * 3.5
      )
    ),
  4: (
    x: (
      $spacer-x * 4
    ),
    y: (
      $spacer-y * 4
    )
  ),
  4-half:
    (
      x: (
        $spacer-x * 4.5
      ),
      y: (
        $spacer-y * 4.5
      )
    ),
  5: (
    x: (
      $spacer-x * 5
    ),
    y: (
      $spacer-y * 5
    )
  ),
  5-half:
    (
      x: (
        $spacer-x * 5.5
      ),
      y: (
        $spacer-y * 5.5
      )
    ),
  6: (
    x: (
      $spacer-x * 6
    ),
    y: (
      $spacer-y * 6
    )
  ),
  6-half:
    (
      x: (
        $spacer-x * 6.5
      ),
      y: (
        $spacer-y * 6.5
      )
    )
) !default;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 769px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;

