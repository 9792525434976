@import '../../styles/base/partials/_colors.scss';

.lg-card {
  position: relative;
  margin: 0 auto 16px;
  height: 210px;
  max-width: 352px;
  background: white;
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
}
.lg-card .lg-card-name {
  line-height: 21px;
  margin-bottom: 4px;
}
.lg-card .lg-card-campus {
  color: #777779;
}
.lg-card .lg-day-of-week {
  font-family: proxima_novasemibold, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-weight: 600;
  line-height: 21px;
}
.lg-card .lg-card-description {
  font-size: 14px;
}
.lg-card .lg-card-meta {
  border-top-left-radius: 4px;
  width: 88px;
  color: white;
  padding: 16px 0 16px 0;
  min-height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lg-card .lg-card-meta-seperator {
  width: 10px;
  border-style: solid;
  border-width: 13px 10px 0 10px;
  transform: translateX(-50%);
  margin-left: 50%;
}
.lg-card .lg-card-side-bar {
  position: absolute;
  text-align: center;
  background-color: #eeeeee;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: 88px;
  height: calc(100% + 2px);
}
.lg-card .lg-card-info {
  font-family: proxima_novabold, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 16px;
  margin-top: 14px;
  padding: 0 4px;
}
.lg-card .lg-card-info-age,
.lg-card .lg-card-info-kids {
  line-height: 14px;
  color: #777779;
}
.lg-card .lg-card-info-kids:before {
  content: '';
  background: url('../../images/check_icon.svg') no-repeat;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-size: 12px;
  margin-right: 4px;
  margin-bottom: -2px;
}
.lg-card .lg-card-content {
  display: inline-block;
  height: calc(100% - 48px);
  margin-left: 100px;
  padding-right: 16px;
  width: calc(100% - 100px);
  position: relative;
  overflow-y: hidden;
  overflow-wrap: break-word;
}

.lg-card .description-fade {
  background: linear-gradient(
    360deg,
    #ffffff 36.2%,
    rgba(255, 255, 255, 0) 114.1%
  );
  position: absolute;
  height: 100px;
  width: 100%;
  bottom: -40px;
}

.lg-card .more-info-text {
  position: absolute;
  bottom: 16px;
  left: 100px;
}

.lg-card .more-info-text:after {
  content: '';
  background: url('../../images/blue-arrow-right.svg') no-repeat;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-size: 8px 8px;
  margin-left: 8px;
}

.lg-card-category-singles .lg-card-meta {
  background-color: $singles;
}
.lg-card-category-mens .lg-card-meta {
  background-color: $mens;
}
.lg-card-category-shared-interest .lg-card-meta {
  background-color: $shared-interest;
}
.lg-card-category-womens .lg-card-meta {
  background-color: $womens;
}
.lg-card-category-married .lg-card-meta {
  background-color: $married;
}
.lg-card-category-everyone-welcome .lg-card-meta {
  background-color: $everyone-welcome;
}
.lg-card-category-parents .lg-card-meta {
  background-color: $lcparents;
}
.lg-card-category-young-adults .lg-card-meta {
  background-color: $young-adults;
}
.lg-card-category-college .lg-card-meta {
  background-color: $college;
}
.lg-card-category-connect-locally .lg-card-meta {
  background-color: $connect-locally;
}
.lg-card-category-neighbor .lg-card-meta {
  background-color: $neighbor;
}
.lg-card-category-mentor .lg-card-meta {
  background-color: $mentor;
}
.lg-card-category-local-partner .lg-card-meta {
  background-color: $local-partner;
}
.lg-card-category-watch-party .lg-card-meta {
  background-color: $watch-party;
}
.lg-card-category-singles .lg-card-info {
  color: $singles;
}
.lg-card-category-mens .lg-card-info {
  color: $mens;
}
.lg-card-category-shared-interest .lg-card-info {
  color: $shared-interest;
}
.lg-card-category-womens .lg-card-info {
  color: $womens;
}
.lg-card-category-married .lg-card-info {
  color: $married;
}
.lg-card-category-everyone-welcome .lg-card-info {
  color: $everyone-welcome;
}
.lg-card-category-parents .lg-card-info {
  color: $lcparents;
}
.lg-card-category-young-adults .lg-card-info {
  color: $young-adults;
}
.lg-card-category-college .lg-card-info {
  color: $college;
}
.lg-card-category-connect-locally .lg-card-info {
  color: $connect-locally;
}
.lg-card-category-neighbor .lg-card-info {
  color: $neighbor;
}
.lg-card-category-mentor .lg-card-info {
  color: $mentor;
}
.lg-card-category-local-partner .lg-card-info {
  color: $local-partner;
}
.lg-card-category-watch-party .lg-card-info {
  color: $watch-party;
}
.lg-card-category-singles .lg-card-meta-seperator {
  border-color: $singles #eeeeee;
}
.lg-card-category-mens .lg-card-meta-seperator {
  border-color: $mens #eeeeee;
}
.lg-card-category-shared-interest .lg-card-meta-seperator {
  border-color: $shared-interest #eeeeee;
}
.lg-card-category-womens .lg-card-meta-seperator {
  border-color: $womens #eeeeee;
}
.lg-card-category-married .lg-card-meta-seperator {
  border-color: $married #eeeeee;
}
.lg-card-category-everyone-welcome .lg-card-meta-seperator {
  border-color: $everyone-welcome #eeeeee;
}
.lg-card-category-parents .lg-card-meta-seperator {
  border-color: $lcparents #eeeeee;
}
.lg-card-category-young-adults .lg-card-meta-seperator {
  border-color: $young-adults #eeeeee;
}
.lg-card-category-college .lg-card-meta-seperator {
  border-color: $college #eeeeee;
}
.lg-card-category-connect-locally .lg-card-meta-seperator {
  border-color: $connect-locally #eeeeee;
}
.lg-card-category-neighbor .lg-card-meta-seperator {
  border-color: $neighbor #eeeeee;
}
.lg-card-category-mentor .lg-card-meta-seperator {
  border-color: $mentor #eeeeee;
}
.lg-card-category-local-partner .lg-card-meta-seperator {
  border-color: $local-partner #eeeeee;
}
.lg-card-category-watch-party .lg-card-meta-seperator {
  border-color: $watch-party #eeeeee;
}
