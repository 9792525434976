.lc-modal {
  position: fixed;
  left:0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #fff;
  overflow-y: auto;

  &.show-modal {
    display: block;
  }

  &.hide-modal {
    display: none;
  }

  .lc-modal-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 84px;
    max-height: 40%;
    z-index: 100;
    border-top: 1px solid #ececec;
    background: #fff;

  }

  .lc-modal-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    z-index: 100;
    border-bottom: 1px solid #ececec;
    background: #fff;

    .lc-modal-close-btn {
      position: absolute;
      right: 0;
      top: 4px;
      padding: 10px;
      font-size: 16px;
      color: #1d1d1d;
      font-weight: bold;
    }
  }

  .lc-modal-content {
    padding: 10px;
    margin-top: 55px;
    margin-bottom: 90px;

    @media only screen and (min-width: 641px) {
      padding: 30px;
    }
  }

  @media only screen and (min-width: 641px) {
    padding: 0 10%;
  }
}