.lg-checkbox {
  display: inline-block;
  border: 2px solid #dad8d6;
  border-radius: 100px;
  color: #999899;
}
.lg-hidden-checkbox {
  position: fixed;
  opacity: 0;
  bottom: -999px;
}
.checkbox-div {
  display: inline-block;
}

.checkbox.custom-checkbox label {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  padding-left: 32px;
  cursor: pointer;
  margin: 0;
}

.checkbox.custom-checkbox label::before,
.checkbox.custom-checkbox label::after {
  position: absolute;
  content: '';
  display: inline-block;
}

.checkbox.custom-checkbox label::before {
  height: 24px;
  width: 24px;
  top: 50%;
  left: 0;
  border: 2px solid #ececec;
  border-radius: 2px;
  transform: translateY(-50%);
}

.checkbox.custom-checkbox label:hover::before {
  background-color: #f4f4f4;
}

.checkbox.custom-checkbox label::after {
  height: 24px;
  width: 24px;
  left: 0;
  top: -4px;
  display: flex;
  transition: tran;
  justify-content: center;
  align-items: center;
}

/*Hide the checkmark by default*/
.checkbox.custom-checkbox input[type='checkbox'] + label::after {
  content: none;
}
/*Unhide the checkmark on the checked state*/
.checkbox.custom-checkbox input[type='checkbox']:checked + label::after {
  content: url('../../images/checkmark-white.svg');
}

.checkbox.custom-checkbox input[type='checkbox']:checked + label::before {
  background-color: #00946d;
  border-color: #00946d;
}
